import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
//import { Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private base_Url = environment.apiUrl;



  private accountApi ="api/account/";
  constructor(public http: HttpClient) { }

  // GetMaterialDropDownData() {
  //   return this.http.get(this.baseurl + this.commonApi + `GeMasterLookupList`).pipe()
  // }
  register(Model) {
    const Url = this.base_Url +  this.accountApi + `Register`;
    return this.http.post(Url, Model).pipe()
  }


  
 // public token: string;
  login(Model) {
    const Url = this.base_Url +this.accountApi+ 'GetLogedInUserDetails';
    return this.http.post(Url, Model).pipe()
  }
  

  UpdateContact(Model) {
    const Url = this.base_Url +this.accountApi+ 'UpdateContact';
    return this.http.post(Url, Model).pipe()
  }
  // register(regModel: object){
  //   const Url = this.base_Url + '/api/Auth/Register';
  //   return this.http.post(Url, regModel).pipe()
  // }
}
