import { UserType, WorkOrderUsedFor } from './Enums';


export class WorkOrderVM {
        Id:number;
        WorkOrderName:string;
        CityId:number;
CreatedBy:string;
UserType:UserType;
WorkOrderUsedFor:WorkOrderUsedFor;
FormType:string;
} 
