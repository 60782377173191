import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StreetService {

  public baseurl=environment.apiUrl;
  private commonApi ="api/common/";
  
  private streetApi ="api/street/";
  constructor(public http: HttpClient) { }

  
  AssignStreet(Model) {
    const Url = this.baseurl +  this.streetApi + `AssignStreet`;
    return this.http.post(Url, Model).pipe()
  }  
  
  UnassignStreet(Model) {
    const Url = this.baseurl +  this.streetApi + `UnassignStreet`;
    return this.http.post(Url, Model).pipe()
  }  

  
  // GetMaterialDropDownData() {
  //   return this.http.get(this.baseurl + this.commonApi + `GeMasterLookupList`).pipe()
  // }
  SaveUpdateStreetData(Model) {
    const Url = this.baseurl +  this.streetApi + `SaveUpdateStreetData`;
    return this.http.post(Url, Model).pipe()
  }  
  GetStreetList(Model) {
    const Url = this.baseurl +  this.streetApi + `GetStreetList`;
    return this.http.post(Url, Model).pipe()
  }  
  
  GetStreetById(id: number) {
    const Url = this.baseurl +  this.streetApi + `GetStreetById/${id}`;
    return this.http.get(Url).pipe()
  }  
 
  GetAssignStreetList(Model) {
    const Url = this.baseurl +  this.streetApi + `GetAssignStreetList`;
    return this.http.post(Url, Model).pipe()
  }  

}
